@import '../../Variables.scss';

.AiLifeEvent {
	--sidebarBorderRadius: 12px;
	--lifeEventPadding: 30px;
	padding: var(--lifeEventPadding);
	box-sizing: border-box;
	min-height: 100%;
	display: flex;

	&__exit {
		position: fixed;
		top: calc($header-desktop-height + 28px);
		right: 28px;
		border: 1px solid var(--blue);
		border-radius: 50px;
		background: var(--white);
		color: var(--blue);
		padding: 15px 22px;
		font-size: 16px;
		font-weight: $fontWeight--bold;
		z-index: 1100;

		&Content {
			display: flex;
			align-items: center;
			gap: 14px;

			&Icon {
				fill: var(--blue);
				width: 20px;
				height: 20px;
			}

			&--mobile {
				display: none;
			}
		}
	}
	
	&__sidebar {
		width: 450px;
		position: fixed;
		height: calc(100% - var(--lifeEventPadding) - var(--lifeEventPadding) - $header-desktop-height);

		&Container {
			flex: 0 0 450px;
		}
	}

	&__progress {
		border-radius: var(--sidebarBorderRadius);
		box-shadow: 0px 14px 42px 0px rgba(0, 0, 0, 0.14);
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__meta {
		border-radius: var(--sidebarBorderRadius) var(--sidebarBorderRadius) 0 0;
		height: 293px;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		box-sizing: border-box;
		padding: 32px 37px;
		position: relative;
		background: url(https://cms.financialfinesse.com/rafpress/media/2020/08/got-a-job.jpg) center center no-repeat;
		background-size: cover;
		z-index: 0;
		flex: 0 0 293px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(180deg, rgba(56, 56, 56, 0.00) 32.57%, rgba(33, 33, 33, 0.90) 100%);
			z-index: -1;
		}

		&Copy {
			position: absolute;
			top: 14px;
			right: 12px;
		}

		&Type {
			background: var(--grey0);
			color: var(--darker-blue);
			font-size: 16px;
			font-weight: $fontWeight--bold;
			margin-bottom: 7px;
			display: inline-flex;
			align-items: center;
			border-radius: 50px;
			padding: 6px 12px 6px 9px;
			gap: 11px;
			align-self: flex-start;

			&Icon {
				width: 15px;
				height: 15px;
			}
			
			&Badge {
				color: var(--blue);
				text-transform: uppercase;
				font-weight: $fontWeight--regular;
				letter-spacing: 1.375px;
			}
		}

		&Title {
			color: var(--white);
			font-size: 34px;
			font-weight: $fontWeight--bold;
		}
	}

	&__copyLink {
		border: 0;
		border-radius: 50px;
		padding: 9px 12px;
		background-color: var(--white);
		color: var(--blue);
		font-size: 16px;
		cursor: pointer;
		font-weight: $fontWeight--bold;

		&Icon {
			width: 16px;
			height: 16px;
			vertical-align: middle;
			margin-right: 6px;
		}
	}

	&__steps {
		background: var(--white);
		border-radius: 0 0 var(--sidebarBorderRadius) var(--sidebarBorderRadius);
		padding: 30px 36px;
		box-sizing: border-box;
		flex: 1;
		overflow-y: auto;

		&Action {
			display: none;
		}

		&Title {
			font-size: 22px;
			font-weight: $fontWeight--bold;
			margin: 0 0 12px;
			padding: 0;
			color: var(--darker-blue);
		}

		&All {
			display: block;
		}

		&Current {
			display: none;
		}
	}

	&__step {
		position: relative;
		--indicatorSize: 30px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 49px;
			left: 16px;
			width: 2px;
			background: var(--x-light-grey);
			bottom: -16px;
		}

		&--complete {

			&::before {
				background: var(--blue);
			}
		}

		&Meta {
			padding: 15px 0;
			display: flex;
			align-items: center;
			gap: 25px;
			$meta: &;

			&Title {
				font-size: 16px;
				font-weight: $fontWeight--bold;
			}

			&Status {
				width: var(--indicatorSize);
				height: var(--indicatorSize);
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid var(--x-light-grey);
				border-radius: 50%;
				flex: 0 0 var(--indicatorSize);

				&Icon {
					width: 20px;
					height: 20px;
				}
			}

			&--inProgress {

				#{ $meta }Status {
					border-color: var(--blue);
				}

				#{ $meta }Title {
					color: var(--blue);
				}
			}

			&--complete {

				#{ $meta }Status {
					border-color: var(--blue);
					background-color: var(--blue);
					
					&Icon {
						color: var(--white);
					}
				}
			}
		}

		&:last-child::before {
			display: none;
		}
	}

	&__subSteps {
		margin-left: 40px;
	}

	&__subStep {
		display: flex;
		align-items: center;
		gap: 20px;
		padding: 10px 20px 10px 24px;
		--indicatorSize: 20px;
		$subStep: &;

		&Status {
			width: var(--indicatorSize);
			height: var(--indicatorSize);
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid var(--x-light-grey);
			border-radius: 50%;
			flex: 0 0 var(--indicatorSize);

			&Icon {
				width: 15px;
				height: 15px;
			}
		}

		&Title {
			flex: 1;
			font-size: 16px;
			font-weight: $fontWeight--regular;
		}

		&--inProgress {

			#{ $subStep }Title {
				font-weight: $fontWeight--bold;
			}
		}

		&--complete {

			#{ $subStep }Status {
				border-color: #d9ebf2;
				background-color: #d9ebf2;
				color: var(--blue);
			}
		}
	}

	&__questionFlow {
		flex: 1;

		.QuestionFlow__container {
			--questionCointainerPadding: 40px;
			max-width: 848px;
			width: 100%;
			padding-left: var(--questionCointainerPadding);
			padding-right: var(--questionCointainerPadding);
			margin-left: auto;
			margin-right: auto;
			box-sizing: border-box;
		}
	}
}

@media (max-width: 1024px) {
	.AiLifeEvent {

		&__sidebar {
			width: 360px;

			&Container {
				flex: 0 0 360px;
			}
		}
		
		&__meta {
			height: 220px;
			flex: 0 0 220px;
		}
	}
}

@media (max-width: 950px) {
	.AiLifeEvent {
		--lifeEventPadding: 0;
		--sidebarBorderRadius: 0;
		display: block;

		&__exit {
			top: calc($header-mobile-height + 14px);
			right: 14px;
			border: 0;
			border-radius: 50%;
			padding: 0;
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
	
			&Content {
	
				&Icon {
					fill: var(--blue);
					width: 11px;
					height: 11px;
				}

				&--desktop {
					display: none;
				}
	
				&--mobile {
					display: block;
				}
			}
		}

		&__sidebar {
			position: static;
			height: auto;
			width: 100%;

			&Container {
				width: 100%;
				position: fixed;
				z-index: 1000;
			}
		}

		&__progress {
			box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.10);
			border-radius: 0 0 12px 12px;
		}

		&__meta {
			height: 193px;
			flex: 0 0 193px;
			padding: 22px 20px;

			&Copy {
				right: 58px;
			}

			&Type {
				font-size: 12px;
			}

			&Title {
				font-size: 26px;
			}
		}

		&__steps {
			padding: 23px 22px;
			border-radius: 0 0 12px 12px;
			position: relative;

			&Action {
				display: flex;
				align-items: center;
				gap: 12px;
				position: absolute;
				top: 19px;
				right: 17px;
				background: none;
				border: 0;
				margin: 0;
				padding: 6px;
				color: var(--blue);
				text-transform: uppercase;
				font-size: 12px;
				font-weight: $fontWeight--bold;
				cursor: pointer;

				&Icon {
					width: 12px;
					height: 12px;
				}
			}

			&Title {
				margin-bottom: 9px;
			}

			&All,
			&Current {
				display: none;

				&--active {
					display: block;
				}
			}

			&Current {

				&Title {
					color: var(--darker-blue);
					font-size: 16px;
					font-weight: $fontWeight--bold;
				}

				&Step {
					font-size: 16px;
					margin: 15px 64px 0;
					position: relative;

					&::before {
						content: '';
						display: block;
						width: 20px;
						height: 20px;
						position: absolute;
						top: 50%;
						left: -40px;
						transform: translateY(-50%);
						border-radius: 50%;
						border: 1px solid var(--blue);
					}
				}
			}
		}

		&__questionFlow {
			padding-top: 300px;
		}
	}
}

@media (max-width: 768px) {
	.AiLifeEvent {
		&__questionFlow {
	
			.QuestionFlow__container {
				--questionCointainerPadding: 10px;
				max-width: 848px;
				width: calc(100% - var(--questionCointainerPadding) - var(--questionCointainerPadding));
				margin-left: var(--questionCointainerPadding);
				margin-right: var(--questionCointainerPadding);
			}
		}
	}
}

@media (max-width: 500px) {
	.AiLifeEvent {

		&__meta {
			height: 161px;
			flex: 0 0 161px;
		}
	}
}